define("ember-lib/routes/authenticated", ["exports", "ember-lib/mixins/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_errors.default, {
    beforeModel: function beforeModel(transition) {
      if (!this.session.get('authenticated')) {
        // IF IT IS NOT AUTHENTICATED
        this.session.set('transition', transition); // SAVE TRANSITION FOR FUTURE REDIRECT

        this.didLogout();
      } else {
        // IF USER IS AUTHENTICATED
        var previoustransition = this.session.get('transition');

        if (previoustransition) {
          // IF TRANSITION WAS SAVED BEFORE
          previoustransition.retry();
          this.session.set('transition', null);
        } else {
          // IF TRANSITION WAS NOT SAVED BEFORE
          if (this.session.get('completed')) {
            this.session.set('completed', false);
          }
        }
      }
    }
  });

  _exports.default = _default;
});
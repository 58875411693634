define("ember-lib/components/inputs/text-field", ["exports", "ember-lib/mixins/isarabic", "ember-lib/mixins/textfield", "ember-lib/mixins/transformers", "ember-lib/mixins/language"], function (_exports, _isarabic, _textfield, _transformers, _language) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend(_isarabic.default, _textfield.default, _language.default, _transformers.default, {
    classNames: ['inputs', 'text-field'],
    attributeBindings: ['computed-mask', 'computedValue:value']
  });

  _exports.default = _default;
});
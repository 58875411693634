define("ember-lib/components/inputs/text-input", ["exports", "ember-lib/mixins/validators", "ember-lib/mixins/childview", "ember-lib/mixins/shake"], function (_exports, _validators, _childview, _shake) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validators.default, _childview.default, _shake.default, {
    classNames: ['inputs', 'text-input'],
    classNameBindings: ['hasContent', 'hasFocus', 'invalid', 'isValid:valid', 'hasLead', 'disabled', 'multiline'],
    attributeBindings: ['size', 'computedPrefix:data-prefix'],
    focus: false,
    defaultLanguage: 'en',
    type: 'text',
    size: 'medium',
    invalid: false,
    autocomplete: 'off',
    maxlength: null,
    computedPrefix: Ember.computed('prefix', 'value', function () {
      var _this = this;

      if (this.get('prefix') && !this.get('value')) {
        Ember.run.scheduleOnce('afterRender', function () {
          _this.set('value', _this.get('prefix'));
        });
      }

      return this.get('prefix');
    }),
    // ACTIONS -----------------------------------------------------------------
    actions: {
      focusIn: function focusIn() {
        this.set('hasFocus', true);
        if (this.focusIn) this.focusIn(this.get('hasContent'), this.get('isValid'));

        if (this.get('required')) {
          this.validate();
        }
      },
      focusOut: function focusOut() {
        this.set('hasFocus', false);
        if (this.focusOut) this.focusOut(this.get('hasContent'), this.get('isValid'));

        if (this.get('required')) {
          this.validate();
        }
      },
      enterPressed: function enterPressed() {
        if (this.get('isValid') && this.enterPressed) {
          this.enterPressed(this.get('hasContent'), this.get('isValid'));
        } else {
          this.validate();
        }
      },
      change: function change(value) {
        if (value && !value.type) {
          if (this.change) this.change(value, this.get('hasContent'), this.get('isValid'));
        }
      }
    },
    // COMPUTED ----------------------------------------------------------------
    hasLead: Ember.computed('lead', function () {
      return !this.validator_empty(this.get('lead'));
    }),
    hasContent: Ember.computed('value', function () {
      return !this.validator_empty(this.get('value'));
    }),
    isValid: Ember.computed('value', function () {
      if (this.get('required') !== false) {
        var criteria = this.get('criteria');
        var value = this.get('value');

        if (criteria) {
          if (typeof criteria === 'string') {
            var method = this["validator_" + criteria];
            return method.call(this, value);
          } else if (typeof criteria === 'function') {
            return criteria.call(this, value);
          }
        } else {
          return this.get('hasContent');
        }
      } else {
        return true;
      }
    }),
    //
    validate: function validate() {
      this.set('invalid', !this.get('isValid'));
    }
  });

  _exports.default = _default;
});
define("ember-lib/mixins/video", ["exports", "ember-data/attr"], function (_exports, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    length: (0, _attr.default)('number'),
    width: (0, _attr.default)('number'),
    height: (0, _attr.default)('number'),
    processed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    processed_at: (0, _attr.default)('date', {
      readOnly: true
    }),
    failed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    failed_at: (0, _attr.default)('date', {
      readOnly: true
    })
  });

  _exports.default = _default;
});
define("ember-lib/mixins/textfield", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    computedValue: Ember.computed('value', function () {
      if (this.transform) {
        return this.toTransformed(this.value);
      } else if (this.mask && (0, _jquery.default)(this.element) && (0, _jquery.default)(this.element).masked) {
        try {
          return (0, _jquery.default)(this.element).masked(this.value);
        } catch (error) {
          return this.value;
        }
      } else {
        return this.value;
      }
    }),
    toExtracted: function toExtracted(value) {
      var transform = this.transform;
      var extractor = this['extractor_' + transform];
      if (this.extractor) extractor = this.extractor;

      if (transform && extractor) {
        return extractor.call(this, value);
      } else {
        return value;
      }
    },
    toTransformed: function toTransformed(value) {
      if (typeof value !== "undefined") {
        value = String(value);
      }

      var transform = this.transform;

      if (transform && value) {
        var transformed = value;

        if (typeof transform === 'string') {
          var method = this["transformer_" + transform];
          transformed = method.call(this, transformed);
        } else if (typeof transform === 'function') {
          transformed = transform(transformed);
        }

        return transformed;
      } else {
        return value;
      }
    },
    'computed-mask': Ember.computed('mask', function () {
      var _this = this;

      Ember.run.later(function () {
        if (_this.mask) {
          (0, _jquery.default)(_this.element).mask(_this.mask, {
            placeholder: _this.placeholder
          });
        } else {
          (0, _jquery.default)(_this.element).unmask();
        }
      });
      return this.mask;
    }),
    didInsertElement: function didInsertElement() {
      if (this.transform) {
        this._elementValueDidChange();
      }
    },
    _elementValueDidChange: function _elementValueDidChange() {
      if (!this.element) return;

      if (this.transform) {
        this.executeTransform();
        this.set('value', this.toExtracted(this.element.value));
      } else if (this.mask) {
        this.set('value', (0, _jquery.default)(this.element).cleanVal());
      } else {
        this.set('value', this.element.value);
      }
    },
    executeTransform: function executeTransform() {
      var transformed = this.toTransformed(this.element.value);
      var value = this.element.value;

      if (value !== transformed) {
        var _this$getInputSelecti = this.getInputSelection(),
            start = _this$getInputSelecti.start;

        this.element.value = transformed;
        start = transformed.length - value.length + start;
        this.setInputSelection(start, start);
      }
    },
    keyUp: function keyUp(event) {
      this._super(event);
    },
    keyDown: function keyDown(event) {
      this._super(event);

      if (event.which === 13) {
        if (this.enterPressed) this.enterPressed();
        if (this.linebreak !== true) event.preventDefault();
      }
    },
    getInputSelection: function getInputSelection() {
      var el = this.get('element');
      var start = 0,
          end = 0,
          normalizedValue,
          range,
          textInputRange,
          len,
          endRange;

      if (typeof el.selectionStart === "number" && typeof el.selectionEnd === "number") {
        start = el.selectionStart;
        end = el.selectionEnd;
      } else {
        range = document.selection.createRange();

        if (range && range.parentElement() === el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");
          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());
          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
            start = end = len;
          } else {
            start = -textInputRange.moveStart("character", -len);
            start += normalizedValue.slice(0, start).split("\n").length - 1;

            if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
              end = len;
            } else {
              end = -textInputRange.moveEnd("character", -len);
              end += normalizedValue.slice(0, end).split("\n").length - 1;
            }
          }
        }
      }

      return {
        start: start,
        end: end
      };
    },
    offsetToRangeCharacterMove: function offsetToRangeCharacterMove(offset) {
      var el = this.get('element');
      return offset - (el.value.slice(0, offset).split("\r\n").length - 1);
    },
    setInputSelection: function setInputSelection(startOffset, endOffset) {
      var el = this.get('element');

      if (typeof el.selectionStart === "number" && typeof el.selectionEnd === "number") {
        el.selectionStart = startOffset;
        el.selectionEnd = endOffset;
      } else {
        var range = el.createTextRange();
        var startCharMove = this.offsetToRangeCharacterMove(startOffset);
        range.collapse(true);

        if (startOffset === endOffset) {
          range.move("character", startCharMove);
        } else {
          range.moveEnd("character", this.offsetToRangeCharacterMove(endOffset));
          range.moveStart("character", startCharMove);
        }

        range.select();
      }
    }
  });

  _exports.default = _default;
});
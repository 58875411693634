define("ember-lib/mixins/childview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _childViewDidInsertElement: Ember.on('init', function () {
      if (this.registerView) this.registerView(this);
    }),
    _childViewWillDestroyElement: Ember.on('willDestroyElement', function () {
      if (this.unregisterView) this.unregisterView(this);
    })
  });

  _exports.default = _default;
});
define("ember-lib/services/session", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    request: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    authenticated: false,
    type: 'admin',
    passport: (0, _emberLocalStorage.storageFor)('passport'),
    // INITIALIZE ------------------------------------------------------------------
    init: function init() {
      this._super();

      if (this.get('passport.id') && this.get('passport.token')) {
        this.set('authenticated', true);
      }
    },
    //
    headers: Ember.computed('passport.{id,token}', function () {
      var result = {};

      if (this.get('passport.id') && this.get('passport.token')) {
        result['Authorization'] = this.get('type') + " " + this.get('passport.id') + "." + this.get('passport.token');
      }

      return result;
    }),
    authenticate: function authenticate(data) {
      this.set('passport.id', data.id);
      this.set('passport.token', data.token);
      this.set("authenticated", true);
    },
    logout: function logout() {
      this.set('passport.id', null);
      this.set('passport.token', null);
      this.set("authenticated", false);
    }
  });

  _exports.default = _default;
});
define("ember-lib/components/inputs/file-droplet", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inputs', 'file-droplet'],
    accept: '',
    multiple: false,
    click: function click() {
      var self = this;
      Ember.run.later(function () {
        var input = (0, _jquery.default)('<input>').attr({
          type: 'file',
          accept: self.get('accept')
        });
        input.on('change', function (event) {
          self.handleInputField(event);
        });
        self.set('input', input);
        self.get('input').click();
      });
    },
    handleInputField: function handleInputField() {
      var file = this.input[0].files[0];
      this.set('file', file);
      if (this.added) this.added(file);

      if (this.get('showPreview')) {
        var reader = new FileReader();
        var self = this;

        reader.onload = function (e) {
          self.set('preview', e.target.result);
          if (self.onPreview) self.onPreview(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
  });

  _exports.default = _default;
});
define("gbs-ember-shared/components/shared/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shared', 'loader'],
    classNameBindings: ['padding'],
    black: true,
    white: false
  });

  _exports.default = _default;
});
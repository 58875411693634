define("gbs-ember-shared/models/image", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-lib/mixins/file", "ember-lib/mixins/image"], function (_exports, _model, _timestamps, _file, _image) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, _file.default, _image.default, {});

  _exports.default = _default;
});
define("gbs-ember-shared/models/user", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "gbs-ember-shared/mixins/contact", "ember-data/attr", "ember-data/relationships", "ember-get-config", "ember-api-actions"], function (_exports, _model, _timestamps, _contact, _attr, _relationships, _emberGetConfig, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, _contact.default, {
    session: Ember.inject.service(),
    company_name: (0, _attr.default)('string'),
    company_short_name: (0, _attr.default)('string'),
    company_size: (0, _attr.default)('string'),
    identifier: (0, _attr.default)('string'),
    reauthenticate: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    order: (0, _relationships.belongsTo)('order', {
      inverse: null
    }),
    has_custom_benefits: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    benefits: (0, _relationships.hasMany)('benefit'),
    incentives: (0, _relationships.hasMany)('incentive'),
    gift_orders: (0, _relationships.hasMany)('gift-order'),
    has_multiple_countries: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    country: (0, _relationships.belongsTo)('country', {
      inverse: null
    }),
    countries: (0, _relationships.hasMany)('country', {
      inverse: null
    }),
    has_hidden_benefits: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    hidden_benefits: (0, _relationships.hasMany)('benefit'),
    has_emails_enabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    emails_enabled: (0, _attr.default)('string'),
    personal_email_required: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    password_required: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    email_verification_required: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    has_received_welcome: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    has_welcome: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    welcome_title: (0, _attr.default)('string'),
    welcome_description: (0, _attr.default)('string'),
    //
    okta: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    okta_client_id: (0, _attr.default)('string'),
    okta_url: (0, _attr.default)('string'),
    //
    custom_font: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    font: (0, _attr.default)('string'),
    //
    origin: (0, _attr.default)('string', {
      defaultValue: 'account'
    }),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    //
    employees: (0, _relationships.hasMany)('employee'),
    //
    logo: (0, _relationships.belongsTo)('image', {
      inverse: null
    }),
    cover: (0, _relationships.belongsTo)('image', {
      inverse: null
    }),
    desktop_cover: (0, _relationships.belongsTo)('image', {
      inverse: null
    }),
    logo_size: (0, _attr.default)('string', {
      defaultValue: 'regular'
    }),
    background_color: (0, _attr.default)('string'),
    text_color: (0, _attr.default)('string'),
    button_background_color: (0, _attr.default)('string'),
    button_text_color: (0, _attr.default)('string'),
    //
    has_android_app: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    android_app_link: (0, _attr.default)('string'),
    has_ios_app: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    ios_app_link: (0, _attr.default)('string'),
    //
    experimental: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    profile_completed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    //
    free: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    // Custom API actions
    syncCustomerIo: (0, _emberApiActions.collectionAction)({
      path: 'sync_customer_io',
      type: 'post'
    }),
    //
    identifierUrl: Ember.computed('identifier', function () {
      return _emberGetConfig.default.APP.host.split('api').join(_emberGetConfig.default.APP.benefits) + "/" + this.identifier;
    }),
    exportRedemptionsUrl: Ember.computed('identifier', function () {
      return "users/" + this.id + "/export-redemptions-" + this.get('session.type');
    }),
    exportEmployeesUrl: Ember.computed('identifier', function () {
      return "users/" + this.id + "/export-employees-" + this.get('session.type');
    })
  });

  _exports.default = _default;
});
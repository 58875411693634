define("gbs-ember-shared/models/gift-order", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-lib/mixins/owner", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _timestamps, _owner, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, _owner.default, {
    status: (0, _attr.default)('string', {
      defaultValue: 'pending'
    }),
    payment_type: (0, _attr.default)('string', {
      defaultValue: 'transfer'
    }),
    employee: (0, _relationships.belongsTo)('employee', {
      inverse: null
    }),
    gift: (0, _relationships.belongsTo)('gift', {
      inverse: null
    }),
    //
    isPaymentTypeTransfer: Ember.computed('payment_type', function () {
      return this.payment_type === "transfer";
    }),
    isPaymentTypeCard: Ember.computed('payment_type', function () {
      return this.payment_type === "card";
    }),
    //
    isStatusCompleted: Ember.computed('status', function () {
      return this.status === "completed";
    }),
    isStatusCancelled: Ember.computed('status', function () {
      return this.status === "cancelled";
    }),
    isStatusPending: Ember.computed('status', function () {
      return this.status === "pending";
    })
  });

  _exports.default = _default;
});
define("ember-lib/routes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.reopen({
    autoreset: true,
    resetController: function resetController() {
      this._super();

      var model = this.get('currentModel');

      if (this.get('autoreset') && model && model.get) {
        if (model.get('isNew')) {
          model.deleteRecord();
        }

        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }

        if (model.get('hasDirtyRelations')) {
          model.rollback();
        }
      }
    }
  });

  _exports.default = _default;
});
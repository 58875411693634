define("ember-lib/mixins/validators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    validator_zip: function validator_zip(string) {
      if (string && string.length === 5) {
        return true;
      } else {
        return false;
      }
    },
    validator_model: function validator_model(model) {
      if (model) {
        return true;
      } else {
        return false;
      }
    },
    validator_date: function validator_date(date) {
      return this.validator_anything(date);
    },
    validator_password: function validator_password(text) {
      return this.validator_anything(text);
    },
    validator_price: function validator_price(text) {
      return Number(text) !== 0;
    },
    validator_number: function validator_number(text) {
      if (Number(text) === 0) {
        return false;
      } else if (typeof text === "number") {
        return true;
      } else if (text) {
        var pattern = new RegExp('^[0-9]+$');
        return pattern.test(String(text));
      } else {
        return false;
      }
    },
    validator_email: function validator_email(text) {
      if (typeof text === "string") {
        var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/;
        return pattern.test(text);
      } else {
        return false;
      }
    },
    validator_empty: function validator_empty(text) {
      if (typeof text !== "undefined" && text !== null && String(text) !== "null") {
        return !/([^\s])/.test(text);
      } else if (typeof text === 'boolean') {
        return false;
      } else {
        return true;
      }
    },
    validator_boolean: function validator_boolean(text) {
      return typeof text === "boolean";
    },
    validator_anything: function validator_anything(text) {
      if (!this.validator_empty(text)) {
        text = String(text).split(' ').join('');
        return text.length >= 2;
      } else {
        return false;
      }
    },
    validator_phone: function validator_phone(string) {
      if (!this.validator_empty(string)) {
        return string.length > 8;
      } else {
        return false;
      }
    },
    validator_url: function validator_url(string) {
      try {
        new URL(string);
        return true;
      } catch (error) {
        return false;
      }
    }
  });

  _exports.default = _default;
});
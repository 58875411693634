define("ember-lib/components/shared/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortBy: 'created_at',
    sortedModel: Ember.computed('sortBy', 'model.[]', 'model.@each.{created_at,isNew,sort}', function () {
      if (!this.get('model')) {
        return null;
      }

      if (!this.get('model').sortBy) {
        return null;
      }

      var filtered = this.get('model').filter(function (item) {
        return !item.isNew;
      });

      if (this.sortBy === 'created_at') {
        return filtered.sortBy(this.sortBy).reverseObjects();
      } else if (typeof this.sortBy === "function") {
        return filtered.sort(this.sortBy);
      } else if (this.sortBy) {
        return filtered.sortBy(this.sortBy);
      } else {
        return filtered;
      }
    })
  });

  _exports.default = _default;
});
define("ember-lib/mixins/owner", ["exports", "ember-data/relationships"], function (_exports, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    owner: (0, _relationships.belongsTo)('user', {
      readOnly: true,
      inverse: null
    })
  });

  _exports.default = _default;
});
define("ember-lib/mixins/image", ["exports", "ember-data/attr"], function (_exports, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    preview: (0, _attr.default)('string', {
      readOnly: true
    }),
    width: (0, _attr.default)('number'),
    height: (0, _attr.default)('number'),
    sizes: (0, _attr.default)('array')
  });

  _exports.default = _default;
});
define("ember-lib/components/shared/switcher-input", ["exports", "ember-lib/mixins/childview"], function (_exports, _childview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_childview.default, {
    classNames: ['shared', 'switcher-input'],
    actions: {
      change: function change(value) {
        if (this.change) this.change(value);
      }
    }
  });

  _exports.default = _default;
});
define("gbs-ember-shared/services/analytics", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super();

      this.script();
    },
    //
    script: function script() {
      if (_emberGetConfig.default.ANALYTICS && _emberGetConfig.default.ANALYTICS.ua) {
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;
          i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments);
          }, i[r].l = 1 * new Date();
          a = s.createElement(o), m = s.getElementsByTagName(o)[0];
          a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m);
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

        if (Array.isArray(_emberGetConfig.default.ANALYTICS.ua)) {
          _emberGetConfig.default.ANALYTICS.ua.map(function (item) {
            window.ga('create', item, 'auto');
          });
        } else {
          window.ga('create', _emberGetConfig.default.ANALYTICS.ua, 'auto');
        }

        window.ga('require', 'ecommerce'); //

        var that = this;
        this.get('router').on('routeDidChange', function (event) {
          if (event.targetName) {
            that.pageview(window.location.pathname);
          }
        });
      }
    },
    //
    shouldinit: function shouldinit() {
      if (this.get('fastboot.isFastBoot') !== true && _emberGetConfig.default.ANALYTICS && _emberGetConfig.default.ANALYTICS.ua) {
        return true;
      } else {
        return false;
      }
    },
    attributes: function attributes() {
      return null;
    },
    hasUser: function hasUser() {
      return false;
    },
    event: function event(category, action, data, label) {
      if (this.shouldinit() === false) return;
      if (!window.ga) return;

      if (_emberGetConfig.default.ANALYTICS.debug === true) {
        console.log('analytics: event', category, action, label, null, data);
      }

      window.ga('send', 'event', category, action, label, null, data);
    },
    pageview: function pageview(location) {
      if (this.shouldinit() === false) return;
      if (!window.ga) return;

      if (_emberGetConfig.default.ANALYTICS.debug === true) {
        console.log('analytics: pageview', location);
      }

      window.ga('send', 'pageview', location);
    }
  });

  _exports.default = _default;
});
define("gbs-ember-shared/services/customerio", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),
    //
    script: function script() {
      if (_emberGetConfig.default.CUSTOMERIO && _emberGetConfig.default.CUSTOMERIO.siteId) {
        window._cio = [];

        (function () {
          var a, b, c;

          a = function a(f) {
            return function () {
              window._cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
            };
          };

          b = ["load", "identify", "sidentify", "track", "page"];

          for (c = 0; c < b.length; c++) {
            window._cio[b[c]] = a(b[c]);
          }

          ;
          var t = document.createElement('script'),
              s = document.getElementsByTagName('script')[0];
          t.async = true;
          t.id = 'cio-tracker';
          t.setAttribute('data-site-id', _emberGetConfig.default.CUSTOMERIO.siteId);
          t.src = 'https://assets.customer.io/assets/track.js';
          s.parentNode.insertBefore(t, s);
        })(); //


        var that = this;
        this.get('router').on('routeDidChange', function (event) {
          if (event.targetName) {
            var url = event.targetName.split('.index').join('');
            url = url.split('.').join('/');
            that.pageview(window.location.pathname);
          }
        });
      }
    },
    //
    shouldinit: function shouldinit() {
      if (this.get('fastboot.isFastBoot') !== true && _emberGetConfig.default.CUSTOMERIO && _emberGetConfig.default.CUSTOMERIO.siteId) {
        return true;
      } else {
        return false;
      }
    },
    attributes: function attributes() {
      return null;
    },
    hasUser: function hasUser() {
      return false;
    },
    // PUBLIC API --------------------------------------------------------------
    identify: function identify() {
      if (this.shouldinit() === false) return;
      if (this.hasUser() === false) return;
      this.script();
      var data = {};
      var object = this.attributes();

      for (var param in object) {
        if (object[param]) {
          data[param] = object[param];
        }
      }

      this.debugger('identify', data);

      window._cio.identify(data);
    },
    event: function event(name, metadata) {
      if (this.shouldinit() === false) return;
      if (this.hasUser() === false) return;
      this.debugger('track', name, metadata);

      window._cio.track(name, metadata);
    },
    pageview: function pageview(location) {
      if (this.shouldinit() === false) return;
      if (this.hasUser() === false) return;
      if (!window._cio) return;
      this.event('pageview', {
        url: location
      });
    },
    //
    debugger: function _debugger(name, action, data) {
      if (this.hasUser() === false) return;

      if (_emberGetConfig.default.CUSTOMERIO.debug === true) {
        if (data) {
          console.log('customerio:', name, action, data);
        } else {
          console.log('customerio:', name, action);
        }
      }
    },
    //
    shutdown: function shutdown() {}
  });

  _exports.default = _default;
});
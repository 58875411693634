define("gbs-ember-shared/components/common/form", ["exports", "ember-lib/components/inputs/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _form.default.extend({
    classNames: ['common'],
    actions: {
      focusIn: function focusIn() {
        this.set('error', null);
      }
    },
    disabled: Ember.computed('processing', function () {
      return this.processing;
    })
  });

  _exports.default = _default;
});
define("gbs-ember-shared/services/segment", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super();

      this.script();
    },
    //
    script: function script() {
      if (_emberGetConfig.default.SEGMENT && _emberGetConfig.default.SEGMENT.writeKey) {
        !function () {
          var analytics = window.analytics = window.analytics || [];
          if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");else {
            analytics.invoked = !0;
            analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];

            analytics.factory = function (t) {
              return function () {
                var e = Array.prototype.slice.call(arguments);
                e.unshift(t);
                analytics.push(e);
                return analytics;
              };
            };

            for (var t = 0; t < analytics.methods.length; t++) {
              var e = analytics.methods[t];
              analytics[e] = analytics.factory(e);
            }

            analytics.load = function (t, e) {
              var n = document.createElement("script");
              n.type = "text/javascript";
              n.async = !0;
              n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
              var a = document.getElementsByTagName("script")[0];
              a.parentNode.insertBefore(n, a);
              analytics._loadOptions = e;
            };

            analytics.SNIPPET_VERSION = "4.1.0";
            analytics.load(_emberGetConfig.default.SEGMENT.writeKey);
            analytics.page();
          }
        }(); //

        var that = this;
        this.get('router').on('routeDidChange', function (event) {
          if (event.targetName) {
            var url = event.targetName.split('.index').join('');
            url = url.split('.').join('/');
            that.pageview(url);
          }
        });
      }
    },
    //
    shouldinit: function shouldinit() {
      if (this.get('fastboot.isFastBoot') !== true && _emberGetConfig.default.SEGMENT && _emberGetConfig.default.SEGMENT.writeKey) {
        return true;
      } else {
        return false;
      }
    },
    attributes: function attributes() {
      return null;
    },
    hasUser: function hasUser() {
      return false;
    },
    pageview: function pageview(url) {
      if (this.shouldinit() === false) return;
      if (!window.analytics) return;
      window.analytics.page(url, {
        title: url,
        url: window.location.href,
        path: window.location.pathname
      }, {
        'integrations': {
          'Salesforce': true
        }
      });

      if (_emberGetConfig.default.SEGMENT.debug) {
        console.log('segment: pageview', url);
      }
    },
    track: function track(event, properties) {
      if (this.shouldinit() === false) return;
      if (!window.analytics) return;
      if (!this.hasUser()) return;
      window.analytics.track(event, properties, {
        'integrations': {
          'Salesforce': true
        }
      });

      if (_emberGetConfig.default.SEGMENT.debug) {
        console.log('segment: track', event, properties);
      }
    },
    identify: function identify() {
      if (this.shouldinit() === false) return;
      if (!window.analytics) return;
      if (!this.hasUser()) return;
      var properties = this.attributes();
      window.analytics.identify(properties.id, properties, {
        'Salesforce': true
      });

      if (_emberGetConfig.default.SEGMENT.debug) {
        console.log('segment: identify', properties.id, properties);
      }
    }
  });

  _exports.default = _default;
});
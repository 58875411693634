define("ember-lib/components/inputs/form", ["exports", "ember-lib/mixins/shake"], function (_exports, _shake) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_shake.default, {
    classNames: ['inputs', "form"],
    classNameBindings: ['disabled'],
    attributeBindings: ['autocomplete'],
    tagName: 'form',
    autocomplete: 'off',
    init: function init() {
      this._super.apply(this, arguments);

      this.views = Ember.ArrayProxy.create({
        content: Ember.A([])
      });
    },
    actions: {
      submit: function submit() {
        this.validate();

        if (this.get('isValid') === true) {
          if (this.complete) this.complete();
        } else {
          this.shake();
        }
      },
      registerView: function registerView(view) {
        this.views.pushObject(view);
      },
      unregisterView: function unregisterView(view) {
        this.views.removeObject(view);
      }
    },
    isValid: Ember.computed('views.@each.isValid', function () {
      var list = this.get('views').filter(function (item) {
        return item.get('required') === true && item.get('isValid') === false;
      });
      return list.length === 0;
    }),
    isInvalid: Ember.computed('isValid', function () {
      return !this.get('isValid');
    }),
    validate: function validate() {
      this.get('views').forEach(function (view) {
        if (view.validate && view.get('required') === true) view.validate();
      });
    },
    shake: function shake() {
      this._super(1);

      this.get('views').filterBy('isValid', false).filterBy('required', true).forEach(function (view, index) {
        if (view.shake) view.shake(index);
      });
    }
  });

  _exports.default = _default;
});
define("ember-lib/mixins/location", ["exports", "ember-data/relationships"], function (_exports, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    location: (0, _relationships.belongsTo)('location', {
      readOnly: true
    })
  });

  _exports.default = _default;
});
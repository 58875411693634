define("gbs-ember-shared/models/product", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-data/attr"], function (_exports, _model, _timestamps, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, {
    title: (0, _attr.default)('string'),
    company_name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    currency: (0, _attr.default)('string'),
    price_yearly: (0, _attr.default)('number'),
    price_monthly: (0, _attr.default)('number'),
    complete_header: (0, _attr.default)('string'),
    complete_subheader: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    free: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    //
    benefits: Ember.computed('products.length', function () {
      var _this = this;

      var list = this.get('store').peekAll('benefit');
      list = list.filter(function (item) {
        var product = item.get('products').findBy('id', _this.get('id'));

        if (product) {
          return true;
        } else {
          return false;
        }
      });
      return list;
    })
  });

  _exports.default = _default;
});
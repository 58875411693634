define("ember-lib/components/inputs/select", ["exports", "ember-lib/mixins/isarabic", "ember-lib/mixins/language"], function (_exports, _isarabic, _language) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_isarabic.default, _language.default, {
    classNames: ['inputs', 'select'],
    attributeBindings: ['value', 'direction:dir', 'language', 'optionsLength'],
    tagName: "select",
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('change', this, function () {
        _this.handle();
      });
    },
    _value: null,
    value: Ember.computed({
      get: function get() {
        return this._value;
      },
      set: function set(_key, value) {
        this._value = value;

        if (this.element && this.element.value !== value) {
          this.element.value = value;
        }

        return this._value;
      }
    }),
    handle: function handle() {
      if (this.element && this.element.value) {
        this.set('value', this.element.value);
        if (this.select) this.select(this.element.value);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super();

      this.handle();
    }
  });

  _exports.default = _default;
});
define("ember-lib/mixins/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      error: function error(_error) {
        return this.handleError(_error);
      }
    },
    handleError: function handleError(error) {
      if (error && error.errors && error.errors[0] && error.errors[0].status && error.errors[0].status.toString() === '401') {
        this.transitionTo('application');
        return false;
      } else {
        return true;
      }
    }
  });

  _exports.default = _default;
});
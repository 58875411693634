define("gbs-ember-shared/models/offer", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _timestamps, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, {
    display: Ember.computed('title', function () {
      return this.title;
    }),
    title: (0, _attr.default)('string'),
    identifier: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    benefit: (0, _relationships.belongsTo)('benefit', {
      inverse: null
    }),
    position: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    code: (0, _attr.default)('string'),
    link: (0, _attr.default)('string'),
    form: (0, _attr.default)('string'),
    instruction: (0, _attr.default)('string'),
    //
    displayTitle: Ember.computed('title', function () {
      return this.title.split('</strong>').join('</strong><span>&nbsp;</span>');
    }),
    //
    computedInstruction: Ember.computed('code', 'instruction', function () {
      if (this.instruction) {
        return this.instruction.split('{{code}}').join('<strong>' + this.code + '</strong>');
      } else {
        return this.instruction;
      }
    })
  });

  _exports.default = _default;
});
define("gbs-ember-shared/mixins/company-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    companySizes: Ember.computed(function () {
      return [{
        id: '0-5',
        display: '0-5'
      }, {
        id: '6-10',
        display: '6-10'
      }, {
        id: '11-50',
        display: '11-50'
      }, {
        id: '51-100',
        display: '51-100'
      }, {
        id: '101-250',
        display: '101-250'
      }, {
        id: '251-500',
        display: '251-500'
      }, {
        id: '500-1,000',
        display: '500-1,000'
      }, {
        id: '1,000+',
        display: '1,000+'
      }];
    })
  });

  _exports.default = _default;
});
define("ember-lib/mixins/file", ["exports", "ember-data/attr"], function (_exports, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    s3_key: (0, _attr.default)('string'),
    mimetype: (0, _attr.default)('string'),
    size: (0, _attr.default)('number')
  });

  _exports.default = _default;
});
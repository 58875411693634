define("gbs-ember-shared/components/shared/image", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shared', 'image'],
    attributeBindings: ['src'],
    tagName: "img",
    width: 640,
    src: Ember.computed('model.id', function () {
      if (this.get('model.id')) {
        var width = Number(this.width);
        var sizes = this.get('model.sizes');

        if (sizes) {
          if (sizes.indexOf(width) === -1) {
            width = Math.max.apply(Math, sizes);
          }

          return _emberGetConfig.default.S3.images + "/" + this.get('model.id') + "/" + width + ".jpg";
        } else {
          return null;
        }
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});
define("gbs-ember-shared/models/incentive", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-data/relationships", "ember-data/attr", "ember-lib/mixins/owner"], function (_exports, _model, _timestamps, _relationships, _attr, _owner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, _owner.default, {
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    steps: (0, _attr.default)('number', {
      defaultValue: 1000
    }),
    duration: (0, _attr.default)('string', {
      defaultValue: '1-week'
    }),
    amount: (0, _attr.default)('number', {
      defaultValue: 100
    }),
    company: (0, _relationships.belongsTo)('company'),
    limit: (0, _attr.default)('number', {
      defaultValue: 10
    })
  });

  _exports.default = _default;
});
define("gbs-ember-shared/models/category", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _timestamps, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, {
    title: (0, _attr.default)('string'),
    identifier: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    position: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    image: (0, _relationships.belongsTo)('image', {
      inverse: null
    })
  });

  _exports.default = _default;
});
define("gbs-ember-shared/mixins/contact", ["exports", "ember-data/attr"], function (_exports, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    mobile: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    first_name: (0, _attr.default)('string'),
    last_name: (0, _attr.default)('string'),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return this.get('first_name') + " " + this.get('last_name');
    }),
    password: (0, _attr.default)('string')
  });

  _exports.default = _default;
});
define("gbs-ember-shared/models/transaction", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-lib/mixins/owner", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _timestamps, _owner, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, _owner.default, {
    order: (0, _relationships.belongsTo)('order', {
      inverse: null
    }),
    value: (0, _attr.default)('number'),
    currency: (0, _attr.default)('string'),
    checkout_payment_id: (0, _attr.default)('string'),
    checkout_response: (0, _attr.default)('object')
  });

  _exports.default = _default;
});
define("gbs-ember-shared/models/gift", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _timestamps, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, {
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    company: (0, _relationships.belongsTo)('company', {
      inverse: null
    }),
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    price: (0, _attr.default)('number'),
    currency: (0, _attr.default)('string', {
      defaultValue: 'usd'
    })
  });

  _exports.default = _default;
});
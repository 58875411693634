define("gbs-ember-shared/models/order", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-lib/mixins/owner", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _timestamps, _owner, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, _owner.default, {
    status: (0, _attr.default)('string', {
      defaultValue: 'pending'
    }),
    product: (0, _relationships.belongsTo)('product', {
      inverse: null
    }),
    period: (0, _attr.default)('string', {
      defaultValue: 'yearly'
    }),
    transactions: (0, _relationships.hasMany)('transaction', {
      inverse: null
    }),
    free: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    //
    value: Ember.computed('period', 'product', function () {
      if (this.period === 'monthly') {
        return this.get('product.price_monthly');
      } else {
        return this.get('product.price_yearly');
      }
    }),
    isStatusCompleted: Ember.computed('status', function () {
      return this.status === "completed";
    }),
    isStatusCancelled: Ember.computed('status', function () {
      return this.status === "cancelled";
    }),
    isStatusPending: Ember.computed('status', function () {
      return this.status === "pending";
    }),
    //
    lastTransaction: Ember.computed('transactions.@each.created_at', function () {
      if (this.get('transactions.length')) {
        var transactions = this.get('transactions').sortBy('created_at');
        return transactions.get('lastObject');
      } else {
        return null;
      }
    }),
    isDue: Ember.computed('lastTransaction.created_at', function () {
      if (this.get('lastTransaction')) {
        var startDate = this.get('lastTransaction.created_at');
        var endDate = new Date();
        var diffTime = Math.abs(endDate - startDate);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays < 29) {
          return false;
        } else {
          return true;
        }
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});
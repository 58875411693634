define("gbs-ember-shared/models/benefit", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _timestamps, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, {
    display: Ember.computed('title', 'products', 'products.firstObject', function () {
      if (this.get('products.firstObject.id')) {
        return this.title + " (" + this.get('products.firstObject.display') + ")";
      } else {
        return this.title;
      }
    }),
    title: (0, _attr.default)('string'),
    identifier: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    company: (0, _relationships.belongsTo)('company', {
      inverse: null
    }),
    image: (0, _relationships.belongsTo)('image', {
      inverse: null
    }),
    header: (0, _attr.default)('string'),
    subheader: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    position: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    products: (0, _relationships.hasMany)('product'),
    categories: (0, _relationships.hasMany)('category'),
    call_to_action: (0, _attr.default)('string'),
    email_copy: (0, _attr.default)('string'),
    email_subject: (0, _attr.default)('string'),
    email_recipient: (0, _attr.default)('string'),
    countries: (0, _relationships.hasMany)('country', {
      inverse: null
    }),
    //
    custom_field_type: (0, _attr.default)('string', {
      defaultValue: 'email'
    }),
    custom_field_header: (0, _attr.default)('string'),
    isCustomFieldTypeEmail: Ember.computed('custom_field_type', function () {
      return this.custom_field_type === 'email';
    }),
    //
    displayTitle: Ember.computed('title', 'company.title', function () {
      return this.get('company.title') + " - " + this.title;
    }),
    //
    isCallToActionCode: Ember.computed('call_to_action', function () {
      return this.call_to_action === 'code';
    }),
    isCallToActionUniqueCode: Ember.computed('call_to_action', function () {
      return this.call_to_action === 'unique-code';
    }),
    isCallToActionLink: Ember.computed('call_to_action', function () {
      return this.call_to_action === 'link';
    }),
    isCallToActionForm: Ember.computed('call_to_action', function () {
      return this.call_to_action === 'form';
    }),
    isCallToActionInternalForm: Ember.computed('call_to_action', function () {
      return this.call_to_action === 'internal-form';
    }),
    isCallToActionEmail: Ember.computed('call_to_action', function () {
      return this.call_to_action === 'email';
    }),
    //
    store: Ember.inject.service(),
    offers: Ember.computed(function () {
      var benefit = this;
      var list = this.get('store').peekAll('offer');
      return list.filter(function (item) {
        return item.get('benefit.id') === benefit.id;
      }).sort(function (a, b) {
        return a.position - b.position;
      });
    })
  });

  _exports.default = _default;
});
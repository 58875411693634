define("ember-lib/components/inputs/label", ["exports", "ember-lib/components/mixins/isarabic"], function (_exports, _isarabic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_isarabic.default, {
    classNames: ['inputs', 'label'],
    attributeBindings: ['language', 'dir'],
    processedValue: Ember.computed('value', function () {
      var _this = this;

      var value = this.get('value');

      if (!value) {
        return null;
      }

      for (var param in this.attrs) {
        if (param !== 'value') {
          var string = this.attrs[param];

          if (string.value) {
            string = value.value;
          }

          value = value.split('{{' + param + '}}').join(string);
        }
      }

      if (value) {
        if (this.isArabic(value)) {
          Ember.run.scheduleOnce('render', function () {
            _this.set('language', 'ar');

            _this.set('dir', 'rtl');
          });
        } else {
          Ember.run.scheduleOnce('render', function () {
            _this.set('language', 'en');

            _this.set('dir', 'ltr');
          });
        }

        var list = this.get('value').split(' ');
        var self = this;
        var processed = list.map(function (item) {
          if (self.isArabic(item) && self.get('language') !== 'ar') {
            return '<span dir="rtl" language="ar">' + item + '</span>';
          } else if (self.isArabic(item) === false && self.get('language') !== 'en') {
            return '<span dir="ltr" language="en">' + item + '</span>';
          } else {
            return item;
          }
        });
        return processed.join(' ');
      } else {
        return this.get('value');
      }
    })
  });

  _exports.default = _default;
});
define("ember-cli-head/templates/components/head-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+B5EkE2v",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"meta\",true],[12,\"name\",\"ember-cli-head-start\",null],[12,\"content\",\"\",null],[10],[11],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[9,\"meta\",true],[12,\"name\",\"ember-cli-head-end\",null],[12,\"content\",\"\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"head-content\",\"headElement\",\"in-element\"]}",
    "meta": {
      "moduleName": "ember-cli-head/templates/components/head-layout.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-ember-shared/models/admin", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "gbs-ember-shared/mixins/contact"], function (_exports, _model, _timestamps, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, _contact.default, {});

  _exports.default = _default;
});
define("ember-lib/components/shared/switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shared', 'switcher'],
    classNameBindings: ['value'],
    click: function click() {
      this.toggleProperty('value');
      if (this.change) this.change(this.get('value'));
    }
  });

  _exports.default = _default;
});
define("ember-lib/mixins/language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    attributeBindings: ['direction:dir', 'language'],
    _init: Ember.on('init', function () {
      this._super();

      this.addObserver('value', this, this.valueDidChange);
      this.valueDidChange();
    }),
    _willDestroyElement: Ember.on('willDestroyElement', function () {
      this._super();

      this.removeObserver('value', this, this.valueDidChange);
    }),
    valueDidChange: function valueDidChange() {
      var value = this.get('value');
      var language;
      var direction = 'ltr';

      if (value) {
        if (this.isArabic(value)) {
          language = 'ar';
        } else {
          language = 'en';
        }
      } else if (this.get('defaultLanguage')) {
        language = this.get('defaultLanguage');
      }

      if (language === 'ar') {
        direction = 'rtl';
      }

      this.set('language', language);
      this.set('direction', direction);
      if (this.change) this.change(value);
    }
  });

  _exports.default = _default;
});
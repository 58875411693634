define("ember-lib/components/inputs/file-input-s3", ["exports", "ember-lib/mixins/childview", "ember-lib/mixins/shake", "ember-lib/mixins/s3upload"], function (_exports, _childview, _shake, _s3upload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_childview.default, _shake.default, _s3upload.default, {
    classNames: ['inputs', 'file-input-s3', 'image-input-s3'],
    classNameBindings: ['invalid', 'isValid:valid'],
    isValid: Ember.computed('model.id', function () {
      if (this.get('model.id')) {
        return true;
      } else {
        return false;
      }
    }),
    validate: function validate() {
      this.set('invalid', !this.get('isValid'));
    }
  });

  _exports.default = _default;
});
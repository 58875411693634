define("gbs-ember-shared/models/employee", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "gbs-ember-shared/mixins/contact", "ember-data/relationships", "ember-data/attr", "ember-lib/mixins/owner", "ember-get-config", "ember-api-actions"], function (_exports, _model, _timestamps, _contact, _relationships, _attr, _owner, _emberGetConfig, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, _contact.default, _owner.default, {
    display: Ember.computed('first_name', 'last_name', function () {
      return this.first_name + " " + this.last_name;
    }),
    owner: (0, _relationships.belongsTo)('user', {
      inverse: null
    }),
    redemptions: (0, _relationships.hasMany)('redemption', {
      inverse: null
    }),
    okta: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    imported: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    verified: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    personal_email: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    platform: (0, _attr.default)('string', {
      defaultValue: "web"
    }),
    country: (0, _relationships.belongsTo)('country', {
      inverse: null
    }),
    //
    has_anniversary_date: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    anniversary_date: (0, _attr.default)('string'),
    has_birthday_date: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    birthday_date: (0, _attr.default)('string'),
    benefit_url: (0, _attr.default)('string'),
    // Custom API actions
    syncCustomerIo: (0, _emberApiActions.collectionAction)({
      path: 'sync_customer_io',
      type: 'post'
    }),
    //
    identifierUrl: Ember.computed('owner.identifier', function () {
      return _emberGetConfig.default.APP.host.split('api').join(_emberGetConfig.default.APP.benefits) + "/" + this.get('owner.identifier');
    })
  });

  _exports.default = _default;
});
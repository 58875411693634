define("gbs-ember-shared/models/redemption", ["exports", "ember-data/model", "ember-lib/mixins/timestamps", "ember-data/relationships", "ember-data/attr"], function (_exports, _model, _timestamps, _relationships, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timestamps.default, {
    owner: (0, _relationships.belongsTo)('user', {
      inverse: null
    }),
    employee: (0, _relationships.belongsTo)('employee', {
      inverse: null
    }),
    benefit: (0, _relationships.belongsTo)('benefit', {
      inverse: null
    }),
    offer: (0, _relationships.belongsTo)('offer', {
      inverse: null
    }),
    platform: (0, _attr.default)('string', {
      defaultValue: "web"
    }),
    custom_field: (0, _attr.default)('string'),
    has_copied: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    has_opened: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    code: (0, _attr.default)('string')
  });

  _exports.default = _default;
});
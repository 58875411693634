define("ember-lib/components/shared/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shared', 'spinner'],
    classNameBindings: ['medium', 'white', 'black'],
    medium: true
  });

  _exports.default = _default;
});
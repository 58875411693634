define("ember-lib/mixins/shake", ["exports", "gsap"], function (_exports, _gsap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    shake: function shake(delay) {
      _gsap.TweenMax.to(this.element, 0.15, {
        x: "+=10",
        ease: Back.easeInOut,
        yoyo: true,
        repeat: 3,
        delay: delay * 0.1
      });
    }
  });

  _exports.default = _default;
});
define("ember-lib/mixins/isarabic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isArabic: function isArabic(text) {
      var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
      var result = pattern.test(text);
      return result;
    }
  });

  _exports.default = _default;
});